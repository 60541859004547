import _export from "../internals/export";
import _objectAssign from "../internals/object-assign";
var $ = _export;
var assign = _objectAssign; // `Object.assign` method
// https://tc39.es/ecma262/#sec-object.assign
// eslint-disable-next-line es-x/no-object-assign -- required for testing

$({
  target: "Object",
  stat: true,
  arity: 2,
  forced: Object.assign !== assign
}, {
  assign: assign
});
export default {};